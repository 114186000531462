<template>
  <div
    class="d-flex justify-space-between"
  >
    <div>
      <h1 class="text-h6 primary--text">VOKAR NRW</h1>
      <h2 class="text-subtitle-1 primary--text font-weight-bold">Naturschutzrechtliches Vorkaufsrecht</h2>
    </div>
    <div>
      <a
        class="d-inline-block"
        title="Externer Link: Landesamt für Natur, Umwelt und Klima Nordrhein-Westfalen"
        target="_blank"
        href="https://www.lanuk.nrw.de/"
        rel="noreferrer"
      >
        <v-img
          src="@/assets/LANUK-Logo.svg"
          alt="Landesamt für Natur, Umwelt und Klima Nordrhein-Westfalen"
          contain
          :height="logoSize.height"
          :width="logoSize.width"
        ></v-img>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logoSize () {
      if (this.$vuetify.breakpoint.xs) {
        return {
          height: 42,
          width: 178.5
        };
      } else {
        return {
          height: 60,
          width: 255
        };
      }
    }
  }
};
</script>
